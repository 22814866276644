export default {
  open: "Ανοιχτά",
  closed: "Κλειστά",
  opensAt: "Ανοίγει στις",
  closesAt: "Κλείνει στις",
  breakfast: "Πρωινό",
  lunch: "Μεσημεριανό",
  dinner: "Βραδινό",
  mainDish: "Κυρίως",
  specialDish: "Χορτοφαγικό",
  salad: "Σαλάτα",
  dessert: "Επιδόρπιο",
  kyrios: "Κυρίως:",
  xortofagiko: "Χορτοφαγηκό:",
  salata: "Σαλάτα:",
  gevma: "Γεύμα",
  deipno: "Δείπνο",
  menou: "Μενού:",
  avriano: "Αυριανό",
  simerino: "Σημερινό",
  orario: "Ωράριο",
  proino: "Πρωινό:",
  mesimeriano: "Μεσημεριανό:",
  weekdays: "Καθημερινές",
  weekendsAndHolidays: "Σ/Κ και Αργίες",
  vradino: "Βραδινό:",
  olesOiMeres: "Όλες οι μέρες",
  monday: "ΔΕΥΤΕΡΑ",
  tuesday: "ΤΡΙΤΗ",
  wednesday: "ΤΕΤΑΡΤΗ",
  thursday: "ΠΕΜΠΤΗ",
  friday: "ΠΑΡΑΣΚΕΥΗ",
  saturday: "ΣΑΒΒΑΤΟ",
  sunday: "ΚΥΡΙΑΚΗ",
  grilledPorkSteak: "Μπριζόλες χοιρινές σχάρας",
  kebabsTomatoSauce: "Σουτζουκάκια με σάλτσα ντομάτας",
  pastitsioPenneBeef: "Παστίτσιο με πέννες και μοσχαρίσιο κιμά",
  schnitzelChicken: "Σνίτσελ κοτόπουλο πανέ",
  cabbageRice: "Λαχανόρυζο",
  boilEmMashEmStickEmInAStew: "Πατάτες φούρνου",
  rice: "Ρύζι",
  choiceOfSeasonalSalads: "Επιλογή από σαλάτα εποχής",
  choiceOfSeasonalFruits: "Επιλογή από φρούτα εποχής",
  peasTaters: "Αρακάς με πατάτες",
  mpriam: "Μπριάμ",
  vegetarian: "Ειδικό μενού για χορτοφάγους και Ιατρικούς λόγους",
  fetaCheese: "Τυρί φέτα",
  chickenLeg: "Μπούτι κοτόπουλο ψητό λεμονάτο",
  porkTomatoCelery: "Χοιρινό κοκκινιστό με σέλινο",
  beefPatty: "Μπιφτεκάκια μοσχαρίσια",
  fishSticks: "Ψαροκροκέτες",
  leekRice: "Πρασόρυζο",
  puree: "Πουρές",
  chickenSoup: "Κοτόσουπα",
  ovenOmelette: "Ομελέτα φούρνου με λαχανικά",
  tatersTurkeySausage: "Πατάτες με λουκάνικα γαλοπούλας",
  bolognese: "Μακαρονάδα Μπολονέζ",
  carbonara: "Μακαρονάδα Καρμπονάρα",
  napolitana: "Μακαρονάδα Ναπολιτέν με λαχανικά",
  gratedCheese: "Τυρί τριμμένο",
  lentilSoup: "Φακές σούπα",
  beanSoup: "Φασολάδα παραδοσιακή",
  chickpeaSoup:"Ρεβύθια σούπα",
  lentilsRice: "Φακόρυζο",
  beansPelion: "Φασολάδα Πηλιορείτικη",
  bakedDessert: "Γλυκό ταψιού",
  porkDemiGlace: "Χοιρινό ψητό με σάλτσα ντεμιγκλάς",
  porkPancetta: "Πανσέτες χοιρινές φούρνου",
  chickenLegCreme: "Μπούτι κοτόπουλο α λα κρεμ",
  chickenMeatballs: "Κεφτεδάκια κοτόπουλο",
  pastaVegMushrooms: "Μακαρονάδα με σάλτσα λαχανικών και μανιτάρια",
  spinachRiceStew: "Σπανακόρυζο",
  orzo: "Κριθαράκι",
  beansTaters: "Φασολάκια με πατάτες",
  spinachRiceStew: "Σπανακόρυζο",
  potatoStew: "Πατάτες γιαχνί",
  friedCod: "Βακαλάος πανέ τηγανητός",
  giantBeans: "Φασόλια γίγαντες με λαχανικά",
  beefPattyGouda: "Μπιφτέκι μοσχαρίσιο με γκούντα",
  greenBeansTaters: "Φασολάκια με πατατούλες",
  pieCheese: "Τυρόπιτα",
  pieSpinachCheese: "Σπανακοτυρόπιτα",
  pieMushrooms: "Μανιταρόπιτα",
  gyrosPork: "Γύρος χοιρινός",
  souvlakiChicken: "Σουβλάκι κοτόπουλο",
  chickenCacciatore: "Φιλέτο κοτόπουλο κατσιατόρε",
  zucchiniBalls: "Κολοκυθοκεφτέδες",
  belgianFries: "Πατάτες τηγανητές",
  pizzaTurkeyGouda: "Πίτσα με γαλοπούλα και γκούντα",
  gyrosChicken: "Γύρος κοτόπουλο",
  pigFromTheVillage: "Χοιρινό εξοχικό",
  falafel: "Φαλάφελ",
  pizzaVegan: "Πίτσα χορτοφαγική με λαχανικά",
  pizzaSpecial: "Πίτσα σπέσιαλ"
  };
