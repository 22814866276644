export default {
    title: "Τίτλος:",
    tilefono: "Τηλέφωνο:",
    email: "Email:",
    ktirio: "Κτήριο",
    mprostiniOpsi: "Μπροστινή όψη πανεπιστημίου",
    grafeio: "Γραφείο:",
    epikoinonisteMe: "Επικονωνήστε με",
    tonTinKathigiti: "τον/την καθηγητή/τρια",
    searchProf: "Εισάγετε όνομα, επίθετο ή email",
    noResults: "Δε βρέθηκε αποτέλεσμα",
    grammateia: "Γραμματεία:",
    istoselidaGrammateias: "Επισκεφτείτε την ιστοσελίδα της γραμματείας",
    iem_department: "Μηχανικών Παραγωγής και Διοίκησης",
    proedrosTmimatos: "Πρόεδρος τμήματος",
    kathigitis: "Καθηγητής",
    kathigitria: "Καθηγήτρια",
    anaplirotisKathigitis: "Αναπληρωτής Καθηγητής",
    epikouriKathigitria: "Επίκουρη Καθηγήτρια",
    epikourosKathigitis: "Επίκουρος Καθηγητής",
    //TODO Add greek professor names
    Aristides: "Αριστείδης",
    Gogoussis: "Γωγούσης",
    Apostolos: "Απόστολος",
    Korlos: "Κορλός",
    Dimitris: "Δημήτρης",
    Manolakis: "Μανωλάκης",
    Simira: "Σημίρα",
    Papadopoulou: "Παπαδοπούλου",
    Panagiotis: "Παναγιώτης",
    Tzionas: "Τζιώνας",
    Georgios: "Γεώργιος",
    Tsirigotis: "Τσιριγώτης",
    Dimitrios: "Δημήτριος",
    Bechtsis: "Μπεχτσής",
    Vasilios: "Βασίλειος",
    Ilioudis: "Ηλιούδης",
    Michail: "Μιχαήλ",
    Kiziroglou: "Κιζήρογλου",
    Theodoros: "Θεόδωρος",
    Kosmanis: "Κοσμάνης",
    Ioannis: "Ιωάννης",
    Mpazios: "Μπάζιος",
    Stilianos: "Στυλιανός",
    Xanthos: "Ξανθός",
    Fotios: "Φώτιος",
    Stergiopoulos: "Στεργιόπουλος",
    Triantafillides: "Τριανταφυλλίδης",
    Tsagaris: "Τσαγκάρης",
    Christos: "Χρήστος",
    Yfoulis: "Υφούλης",
    Eirini: "Ειρήνη",
    Aivazidou: "Αϊβαζίδου",
    Pavlos: "Παύλος",
    Aisopoulos: "Αϊσόπουλος",
    Bialas: "Μπιάλας",
    Nikolaos: "Νικόλαος",
    Tapoglou: "Ταπόγλου",
    Konstantinos: "Κωνσταντίνος",
    Tsongas: "Τσογγάς",
    Tziourtzioumis: "Τζιουρτζιούμης",
    Fotini: "Φωτεινή",
    Andras: "Άνδρας",
    Mihos: "Μίχος",
    Papadopoulos: "Παπαδόπουλος",
    Karafillias: "Καραφυλλίας",
    Athanasios: "Αθανάσιος",
    Arvanitidis: "Αρβανιτίδης",
    Maniotis: "Μανιώτης",
    Marianthi: "Μαρίανθη",
    Kermenidou: "Κερμενίδου",
    Kalliopi: "Καλλιόπη",
    Kravari: "Κράβαρη",
    Moussa: "Μούσσα",
    Basar: "Μπασάρ",
    Alexandros: "Αλέξανδρος",
    Astaras: "Αστάρας",
    Charalampos: "Χαράλαμπος",
    Galatsopoulos: "Γαλατσόπουλος",
    Chrisanthi: "Χρυσάνθη",
    Georgakarakou: "Γεωργαράκου",
    Kamoutsis: "Καμούτσης",
    Koutsoudakis: "Κουτσουδάκης",
    Kiklis: "Κύκλης",
    Kostoglou: "Κώστογλου",
    Professor: "Καθηγητής/Καθηγήτρια",
    AssociateProfessor: "Αναπληρωτής Καθηγητής/Αναπληρώτρια Καθηγήτρια",
    AssistantProfessor: "Επίκουρος Καθηγητής/Επίκουρη Καθηγήτρια",
    LaboratoryTeachingStaff: "ΕΔΙΠ",
    SpecialTechnicalStaff: "Ειδικό Τεχνικό Προσωπικό",
    UniversityScholar: "Ακαδημαϊκοί Υπότροφοι",
    AcademicScholar: "Ακαδημαϊκοί Υπότροφοι",
    TeachingStafffromotherDepartment: "Διδακτικό προσωπικό από άλλα Τμήματα"
};
