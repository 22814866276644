export default {
    title: "Title:",
    tilefono: "Phone:",
    email: "Email:",
    ktirio: "Building",
    mprostiniOpsi: "Front side of the university",
    grafeio: "Office:",
    epikoinonisteMe: "Contact with",
    tonTinKathigiti: "the professor",
    searchProf: "Enter name, surname or email",
    noResults: "No results found",
    grammateia: "Secretariat:",
    istoselidaGrammateias: "Visit secretariat's website",
    iem_department: "Industrial Engineering and Management",
    kosmitoras: "Dean",
    proedrosTmimatos: "Dean",
    kathigitis: "Professor",
    kathigitria: "Professor",
    Professor: "Professor",
    AssociateProfessor: "Associate Professor",
    AssistantProfessor: "Assistant Professor",
    LaboratoryTeachingStaff: "Laboratory Teaching Staff",
    SpecialTechnicalStaff: "Special Technical Staff",
    UniversityScholar: "University Scholar",
    AcademicScholar: "Academic Scholar",
    TeachingStafffromotherDepartment: "Teaching Staff from other Departments"
    //TODO
};
