export default {
    apostolidis:"Georgios David Apostolidis",
    davios:"Evaggelos Davios",
    iosifidis:"Efstathios Iosifidis",
    konstantinidis:"Konstantinos Konstantinidis",
    bakalis:"Paris Bakalis",
    nasis:"Prodromos Nasis",
    omiliadis:"Socrates Omiliadis",
    patsoyras:"Christos Fanourios Patsoyras",
    fakidis:"Georgios Fakidis",
    bandisast: "Aster Bandis"
};