export default {
    elearning: "e-Learning",
    portal: "Student Portal",
    current_department: "Current Department: ",
    close: "Close",
    eudoxus: "Eudoxus",
    initial_page: "Home (***CURRENTLY IN DEVELOPMENT***)",
    course_schedule: "Course Schedules",
    restaurant: "Restaurant",
    library: "Library",
    exams_schedule: "Exams Schedule",
    academic_personnel: "Academic Personnel",
    map: "Maps",
    other_services: "Other Services",
    announcements: "Announcements",
    orkomosies: "Graduation",
    foititiki_merimna: "Student Care",
    freshmen_guides: "Freshmen",
    faq_title: "Frequently Asked",
    faq_subtitle: "You will find answers that might be of interest to you.",
    masters: "Masters",
};