export default {
    elearning: "Ασύγχρονη Εκπαίδευση",
    portal: "Φοιτητολόγιο",
    current_department: "Έχεις επιλέξει: ",
    close: "Κλείσιμο",
    eudoxus: "Εύδοξος",
    initial_page: "Αρχική (***ΣΕΛΙΔΑ ΥΠΟ ΑΝΑΠΤΥΞΗ***)",
    course_schedule: "Πρόγραμμα Μαθημάτων",
    restaurant: "Εστιατόριο",
    library: "Βιβλιοθήκη",
    exams_schedule: "Πρόγραμμα Εξετάσεων",
    academic_personnel: "Ακαδημαϊκό Προσωπικό",
    map: "Χάρτης",
    other_services: "Υπόλοιπες Υπηρεσίες",
    announcements: "Ανακοινώσεις",
    orkomosies: "Ορκομωσίες",
    foititiki_merimna: "Φοιτητική Μέριμνα",
    freshmen_guides: "Πρωτοετείς",
    faq_title: "Συχνές Ερωτήσεις",
    faq_subtitle: "Εδώ θα βρείτε απαντήσεις σε ερωτήσεις που ενδέχεται να σας ενδιαφέρουν.",
    masters: "Μεταπτυχιακά"
};