import i18n from "../../../i18n"
export const professors = {
    department: i18n.t("iem_department"), //GODS BLESS CHATGPT
    professors:
        [
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Aristides'),
                lname: i18n.t('Gogoussis'),
                title: i18n.t('Professor'),
                tel: '+30 2310-013-278',
                email: 'gogoussis@ihu.gr',
                building: 'Central Building',
                office: '3009B',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Apostolos'),
                lname: i18n.t('Korlos'),
                title: i18n.t('Professor'),
                tel: '+30 2310 013399',
                email: 'apkorlos@vt.teithe.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Dimitris'),
                lname: i18n.t('Manolakis'),
                title: i18n.t('Professor'),
                tel: '+30 2310-013-437',
                email: 'dmanol@ihu.gr',
                building: 'Automation/Informatics Building',
                office: '213',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Simira'),
                lname: i18n.t('Papadopoulou'),
                title: i18n.t('Professor'),
                tel: '+30 2310-013-993',
                email: 'shmira@ihu.gr',
                building: 'Automation/Informatics Building',
                office: '103',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Panagiotis'),
                lname: i18n.t('Tzionas'),
                title: i18n.t('Professor'),
                tel: '+30 2310-013-101',
                email: 'ptzionas@ihu.gr',
                building: 'Automation/Informatics Building',
                office: '214',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Georgios'),
                lname: i18n.t('Tsirigotis'),
                title: i18n.t('Professor'),
                tel: '+30 2310-013-304',
                email: 'tsirigo@ihu.gr',
                building: 'Automation/Informatics Building',
                office: '104',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Dimitrios'),
                lname: i18n.t('Bechtsis'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-698',
                email: 'dimbec@ihu.gr',
                building: 'Central Building',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Vasilios'),
                lname: i18n.t('Ilioudis'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-056',
                email: 'ilioudis@autom.teithe.gr',
                building: 'Automation/Informatics Building',
                office: '215',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Michail'),
                lname: i18n.t('Kiziroglou'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-412',
                email: 'm.kiziroglou@autom.teithe.gr',
                building: 'Central Building',
                office: '3011',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Theodoros'),
                lname: i18n.t('Kosmanis'),
                title: i18n.t('Associate Professor'),
                tel: '+30 2310 013039',
                email: 'kosmanis@vt.teithe.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Ioannis'),
                lname: i18n.t('Mpazios'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310 013863',
                email: 'ioannis.bazios@yahoo.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Stilianos'),
                lname: i18n.t('Xanthos'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-303',
                email: 'xanthos@autom.teithe.gr',
                building: 'Automation/Informatics Building',
                office: '220',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Fotios'),
                lname: i18n.t('Stergiopoulos'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-401',
                email: 'fstergio@autom.teithe.gr',
                building: 'Automation/Informatics Building',
                office: '111',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Dimitris'),
                lname: i18n.t('Triantafillides'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-409',
                email: 'drdimitri@autom.teithe.gr',
                building: 'Central Building',
                office: '3008 & 3010A',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Apostolos'),
                lname: i18n.t('Tsagaris'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-056',
                email: 'tsagaris@autom.teithe.gr',
                building: 'Central Building',
                office: '3009A',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Christos'),
                lname: i18n.t('Yfoulis'),
                title: i18n.t('AssociateProfessor'),
                tel: '+30 2310-013-994',
                email: 'cyfoulis@autom.teithe.gr',
                building: 'Automation/Informatics Building',
                office: '103',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Eirini'),
                lname: i18n.t('Aivazidou'),
                title: i18n.t('AssistantProfessor'),
                tel: '',
                email: 'aveirini@iem.ihu.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Pavlos'),
                lname: i18n.t('Aisopoulos'),
                title: i18n.t('AssistantProfessor'),
                tel: '+30 2310 013264',
                email: 'paisopol@vt.teithe.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Christos'),
                lname: i18n.t('Bialas'),
                title: i18n.t('AssistantProfessor'),
                tel: '-',
                email: 'cbialas01@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Nikolaos'),
                lname: i18n.t('Tapoglou'),
                title: i18n.t('AssistantProfessor'),
                tel: '-',
                email: 'ntapoglou@iem.ihu.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Konstantinos'),
                lname: i18n.t('Tsongas'),
                title: i18n.t('AssistantProfessor'),
                tel: '-',
                email: 'ktsongas@iem.ihu.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Dimitrios'),
                lname: i18n.t('Tziourtzioumis'),
                title: i18n.t('AssistantProfessor'),
                tel: '+30 2310-013-394',
                email: 'dtziour@vt.teithe.gr',
                building: 'Vehicle Building',
                office: 'Ground Floor, Internal Combustion Engine Workshop I',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Fotini'),
                lname: i18n.t('Papadopoulou'),
                title: i18n.t('AssistantProfessor'),
                tel: '+30 2310-013-055',
                email: 'papafot@autom.teithe.gr',
                building: 'Automation/Informatics Building',
                office: '215',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Christos'),
                lname: i18n.t('Andras'),
                title: i18n.t('LaboratoryTeachingStaff'),
                tel: '+30 2310013425',
                email: 'andras@autom.teithe.gr',
                building: 'Central Building',
                office: '3010Β',
            },

            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Fotios'),
                lname: i18n.t('Mihos'),
                title: i18n.t('SpecialTechnicalStaff'),
                tel: '',
                email: 'michos@vt.teithe.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Georgios'),
                lname: i18n.t('Papadopoulos'),
                title: i18n.t('SpecialTechnicalStaff'),
                tel: '+30 2310013943',
                email: 'gpapadopoulos@vt.teithe.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Dimitrios'),
                lname: i18n.t('Karafillias'),
                title: i18n.t('SpecialTechnicalStaff'),
                tel: '+30 2310013264',
                email: 'dkarafil@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Athanasios'),
                lname: i18n.t('Arvanitidis'),
                title: i18n.t('UniversityScholar'),
                tel: '',
                email: 'ath.arvanitidis@yahoo.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Nikolaos'),
                lname: i18n.t('Maniotis'),
                title: i18n.t('UniversityScholar'),
                tel: '',
                email: 'nimaniot@physics.auth.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Marianthi'),
                lname: i18n.t('Kermenidou'),
                title: i18n.t('UniversityScholar'),
                tel: '',
                email: 'kermenidoumarianthi@hotmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Kalliopi'),
                lname: i18n.t('Kravari'),
                title: i18n.t('UniversityScholar'),
                tel: '',
                email: 'kkravari@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Moussa'),
                lname: i18n.t('Basar'),
                title: i18n.t('UniversityScholar'),
                tel: '',
                email: 'bachmoussa@hotmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Alexandros'),
                lname: i18n.t('Astaras'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'astaras@act.edu',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Charalampos'),
                lname: i18n.t('Galatsopoulos'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'cgalatso@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/female.jpg',
                fname: i18n.t('Chrisanthi'),
                lname: i18n.t('Georgakarakou'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'georgakch@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Konstantinos'),
                lname: i18n.t('Kamoutsis'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'Kostas.k.1925@gmail.com',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Georgios'),
                lname: i18n.t('Koutsoudakis'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'automatistis@hotmail.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Fotios'),
                lname: i18n.t('Kiklis'),
                title: i18n.t('AcademicScholar'),
                tel: '',
                email: 'kiklisfotios@yahoo.gr',
                building: '',
                office: '',
            },
            {
                imgUrl: 'https://www.iem.ihu.gr/en/assets/team/male.jpg',
                fname: i18n.t('Vasilios'),
                lname: i18n.t('Kostoglou'),
                title: i18n.t('TeachingStafffromotherDepartment'),
                tel: '+30 2310013294',
                email: 'vkostogl@it.teithe.gr',
                building: '',
                office: '',
            }
        ]
}