export default {
    apostolidis:"Γεώργιος Δαυίδ Αποστολίδης",
    davios:"Ευάγγελος Δάβιος",
    iosifidis:"Ευστάθιος Ιωσηφίδης",
    konstantinidis:"Κωνσταντίνος Κωνσταντινίδης",
    bakalis:"Πάρης Μπακάλης",
    nasis:"Πρόδρομος Νάσης",
    omiliadis:"Σωκράτης Ομιλιάδης",
    patsoyras:"Χρήστος Φανούριος Πατσούρας",
    fakidis:"Γεώργιος Φακίδης",
    bandisast:"Αστέριος Μπαντής"
};