export default {
  open: "Open",
  closed: "Closed",
  opensAt: "Opens at",
  closesAt: "Closes at",
  breakfast: "Breakfast",
  lunch: "Lunch",
  dinner: "Dinner",
  mainDish: "Main Dish",
  specialDish: "Vegetarian",
  salad: "Salad",
  dessert: "Dessert",
  kyrios: "Main dish:",
  xortofagiko: "Vegetarian:",
  salata: "Salad:",
  gevma: "Lunch",
  deipno: "Dinner",
  menou: "Menu:",
  avriano: "Tomorrow's",
  simerino: "Today's",
  orario: "Schedule",
  proino: "Breakfast:",
  mesimeriano: "Lunch:",
  weekdays: "Weekdays",
  weekendsAndHolidays: "Weekends and national holidays",
  vradino: "Dinner:",
  olesOiMeres: "Days",
  monday: "MONDAY",
  tuesday: "TUESDAY",
  wednesday: "WEDNESDAY",
  thursday: "THURSDAY",
  friday: "FRIDAY",
  saturday: "SATURDAY",
  sunday: "SUNDAY",
  grilledPorkSteak: "Grilled pork chops",
  kebabsTomatoSauce: "Kebabs with tomato sauce",
  pastitsioPenneBeef: "Pastitsio with penne and beef",
  schnitzelChicken: "Chicken schnitzel",
  cabbageRice: "Cabbage rice",
  boilEmMashEmStickEmInAStew: "Baked potatoes",
  rice: "Rice",
  choiceOfSeasonalSalads: "Choice of seasonal salads",
  choiceOfSeasonalFruits: "Choice of seasonal fruits",
  peasTaters: "Peas with potatoes",
  mpriam: "Mpriam (vegetable stew)",
  vegetarian: "Special menu for vegetarians and medical reasons",
  fetaCheese: "Feta cheese",
  chickenLeg: "Roasted lemon chicken leg",
  porkTomatoCelery: "Pork stew with celery",
  beefPatty: "Beef patties",
  fishSticks: "Fish sticks",
  leekRice: "Leek rice",
  puree: "Mashed potatoes",
  chickenSoup: "Chicken soup",
  ovenOmelette: "Baked omelette with vegetables",
  tatersTurkeySausage: "Potatoes with turkey sausages",
  bolognese: "Bolognese pasta",
  carbonara: "Carbonara pasta",
  napolitana: "Napolitana pasta with vegetables",
  gratedCheese: "Grated cheese",
  lentilSoup: "Lentil soup",
  beanSoup: "Traditional bean soup",
  chickpeaSoup: "Chickpea soup",
  lentilsRice: "Lentil rice",
  beansPelion: "Pelion-style bean soup",
  bakedDessert: "Baked dessert",
  porkDemiGlace: "Roasted pork with demi-glace sauce",
  porkPancetta: "Roasted pork belly",
  chickenLegCreme: "Chicken leg with cream sauce",
  chickenMeatballs: "Chicken meatballs",
  pastaVegMushrooms: "Pasta with vegetable and mushroom sauce",
  spinachRiceStew: "Spinach rice stew",
  orzo: "Orzo pasta",
  beansTaters: "Beans with potatoes",
  spinachRiceStew: "Spinach rice stew",
  potatoStew: "Potato stew",
  friedCod: "Fried cod",
  giantBeans: "Giant beans with vegetables",
  beefPattyGouda: "Beef patty with Gouda cheese",
  greenBeansTaters: "Green beans with potatoes",
  pieCheese: "Cheese pie",
  pieSpinachCheese: "Spinach and cheese pie",
  pieMushrooms: "Mushroom pie",
  gyrosPork: "Pork gyros",
  souvlakiChicken: "Chicken souvlaki",
  chickenCacciatore: "Chicken cacciatore",
  zucchiniBalls: "Zucchini balls",
  belgianFries: "Belgian fries",
  pizzaTurkeyGouda: "Pizza with turkey and Gouda cheese",
  gyrosChicken: "Chicken gyros",
  pigFromTheVillage: "Village-style pork",
  falafel: "Falafel",
  pizzaVegan: "Vegan pizza with vegetables",
  pizzaSpecial: "Special pizza"
};
