export const LIBRARY_OPENING_HOURS = {
    on_weekdays: {
        start: "8:00",
        end: "15:00",
    },
    on_saturday: {
        start: null,
        end: null,
    },
    on_sunday: {
        start: null,
        end: null,
    },
};

export const LIBRARY_PHONE_LIST = [
    "2310013695",
    "2310013473"
];